import React from 'react';
import { styled } from '@glitz/react';
import { kappa, sigma, pixelsToUnit } from 'Shared/Style';
import Html from 'Shared/Partials/PropertyTypes/Html';
import { Section, Layout as PageLayout } from 'Shared/PageLayout';
import Viewport, { ForExtensive } from 'Shared/Viewport';

type PropType = {
  description: Scope.XhtmlString;
};

const Container = styled.div({
  textAlign: 'center',
  color: '#656565',
  lineHeight: 'normal',
});

const Mobile = styled(Container, {
  marginTop: pixelsToUnit(50),
  fontSize: kappa,
});

const Desktop = styled(Container, {
  marginTop: pixelsToUnit(130),
  fontSize: sigma,
});

const MobileSeo = (props: React.HTMLProps<HTMLElement>) => (
  <Mobile className="category-seo mobile">{props.children}</Mobile>
);

const DesktopSeo = (props: React.HTMLProps<HTMLElement>) => (
  <Desktop className="category-seo desktop">{props.children}</Desktop>
);

const SeoText = (props: PropType) =>
  props.description ? (
    <Section layout={PageLayout.ZeroToOne}>
      <ForExtensive>
        <div />
      </ForExtensive>
      <Viewport>
        {(isCompact: boolean) => (
          <Html
            html={props.description.html}
            // tslint:disable-next-line:jsx-no-lambda
            component={(props) => (isCompact ? <MobileSeo {...props} /> : <DesktopSeo {...props} />)}
          />
        )}
      </Viewport>
    </Section>
  ) : null;

export default SeoText;
