/**
 * @ComponentFor CategoryViewModel
 */
import React from 'react';
import { styled } from '@glitz/react';
import { EpiProperty } from '@avensia/scope-episerver';
import { PageType } from 'Shared/State';
import ProductListing from 'Product/ProductListing';
import { Main, Appearance as PageAppearance } from 'Shared/PageLayout';
import Breadcrumbs from 'Shared/Breadcrumbs';
import { alpha, delta, eta, sigma, pixelsToUnit, minMediumMediaQuery } from 'Shared/Style';
import CategoryType from './CategoryViewModel.type';
import SeoText from './SeoText';
import ProductListingPageType from 'Product/ProductListing/Pages/ProductListingPageViewModel.type';

const H1 = styled.h1({
  display: 'block',
  fontSize: delta,
  fontWeight: 'bold',
  letterSpacing: pixelsToUnit(0.6),
  lineHeight: 'normal',
  textAlign: 'center',
  textTransform: 'lowercase',
  ':first-letter': {
    textTransform: 'uppercase',
  },
  [minMediumMediaQuery]: {
    display: 'inline-block',
    fontSize: alpha,
    fontWeight: 'normal',
    textAlign: 'left',
  },
});

type PropType = CategoryType & PageType & ProductListingPageType;

export default (props: PropType) => {
  const { breadcrumbs, ...restProps } = props;
  return (
    <Main appearance={PageAppearance.Narrow}>
      <ProductListing
        topContent={<EpiProperty component={(innerProps) => <div {...innerProps} />} for={props.content.topArea} />}
        breadcrumbs={
          <Breadcrumbs
            css={{
              fontSize: sigma,
              letterSpacing: pixelsToUnit(0.5),
              marginBottom: pixelsToUnit(5),
              textAlign: 'center',
              [minMediumMediaQuery]: {
                fontSize: eta,
                letterSpacing: pixelsToUnit(0.2),
                marginBottom: pixelsToUnit(10),
                textAlign: 'left',
              },
            }}
            breadcrumbs={breadcrumbs.slice(0, breadcrumbs.length - 1)}
          />
        }
        pageTitle={
          <EpiProperty
            // tslint:disable-next-line:jsx-no-lambda
            component={(innerProps) => <H1 {...innerProps} />}
            for={props.content.displayName}
          />
        }
        {...restProps}
      />
      <SeoText description={props.description} />
    </Main>
  );
};
